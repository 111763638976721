<script setup>

</script>

<template>
  <a @click.prevent href="/" @click="$router.push('/')" class="navbar-logotype">
    <picture>
      <source :srcset="'https://io.tsrealty.ru/photo/img/images/logo.webp'" type="image/webp"
              class="navbar__logo">
      <img
          src="https://io.tsrealty.ru/photo/img/images/logo.png"
          class="navbar__logo"
          alt="Logo"
      />
    </picture>

  </a>
</template>

<style lang="scss">
.navbar-logotype {
  & img {
    min-width: 100px;
    width: 100%;
    height: auto;
  }
}
</style>