<template>
  <div class="background-logo-arrow">
    <svg viewBox="0 0 600 325" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="logo-back">
        <path d="M275.188 325L600 0L0 162.5L275.188 325Z" fill="#FFF1E9"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "background-logo-arrow"
}
</script>

<style lang="scss">
.background-logo-arrow {
  position: absolute;
  z-index: 1;
  bottom: calc(50% - 300px);
  right: 50%;
  transform: translate(50%, 50%);
  width: 1819px;
  height: 985px;
  & #logo-back {
    width: 100%;
    height: 100%;
  }
}
</style>