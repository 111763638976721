<template>
  <header class="header-nav">
    <nav class="navbar">
      <TSMobileMenu v-if="visibleMenu" @closeMenu="showMenu"/>
      <div class="navbar__body">
        <div class="navbar__logotype">
          <navbar-logotype/>
        </div>
        <div class="navbar__menu">
          <navbar-menu/>
        </div>
        <div class="navbar__contacts">
          <navbar-contacts/>
        </div>
        <div
            class="navbar__burger-button"
            @click="showMenu"
            :class="{'navbar__burger-button_close': visibleMenu}"
        >
          <span></span>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'navbar-module',
};
</script>

<script setup>
import TSMobileMenu from "@/modules/NavbarModule/components/TSMobileMenu.vue";
import NavbarContacts from "@/modules/NavbarModule/components/NavbarContacts.vue";
import NavbarLogotype from "@/modules/NavbarModule/components/NavbarLogotype.vue";
import NavbarMenu from "@/modules/NavbarModule/components/NavbarMenu.vue";
import {ref, watch} from "vue";

let visibleMenu = ref(false);

const showMenu = () => {
  visibleMenu.value = !visibleMenu.value;
}

watch(visibleMenu, newVal => {
  document.body.classList.toggle('_lock');
})
</script>

<style lang="scss">
@import "@/assets/scss/media";

.header-nav {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 12px 30px;
  background-color: var(--color-white);
}

.navbar {
  display: flex;
  justify-content: center;

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    max-width: 1920px;
    width: 100%;
  }

  &__logotype {
    max-width: 180px;
    width: 100%;
  }

  &__menu {
    flex: 100%;
  }

  &__contacts {
    max-width: 365px;
  }

  &__burger-button {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 7px 2px;
    display: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    & > span, &:before, &:after {
      background: var(--color-burger-main);
      border-radius: 2px;
      width: 100%;
      height: 3px;
      display: block;
      position: relative;
    }

    & > span {
      top: 50%;
      transform: translate(0, 50%);
    }

    &:before {
      content: '';
      transition: all 0.3s ease 0s;
      top: 0;
    }

    &:after {
      content: '';
      transition: all 0.3s ease 0s;
      background: var(--color-red);
      bottom: 0;
    }

    &_close {
      & > span {
        display: none;
      }

      &:before {
        transform: rotate(-45deg) translate(0, 50%);
        top: 50%;
      }

      &:after {
        bottom: -50%;
        transform: rotate(45deg) translate(0, -50%);
      }
    }
  }
}

@media screen and (max-width: $media-width-medium) {
  .navbar {
    &__logotype {
      max-width: 160px;
    }
  }
}

@media screen and (max-width: $media-width-medium-narrow) {
  .navbar {
    &__logotype {
      max-width: 100px;
    }
  }
}

@media screen and (max-width: $media-width-small) {
  .header-nav {
    padding: 12px 20px;
  }
  .navbar {

    &__body {
      gap: 5px;
    }

    &__menu {
      display: none;
    }

    &__burger-button {
      display: block;
    }
  }
}

@media screen and (max-width: $media-width-micro) {
  .navbar {
    &__contacts {
      font-size: 15px;
      line-height: 18px;
    }
  }
}
</style>