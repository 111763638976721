<script setup>

</script>

<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="mail-icon">
        <path d="M4.5 4H20.5C21.6 4 22.5 4.9 22.5 6V18C22.5 19.1 21.6 20 20.5 20H4.5C3.4 20 2.5 19.1 2.5 18V6C2.5 4.9 3.4 4 4.5 4Z"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.5 6L12.5 13L2.5 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>

</template>

<style lang="scss">
  #mail-icon {
    stroke: inherit;
  }
</style>