<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="whatsapp">
      <rect width="30" height="30" rx="15" fill="#48C95F"/>
      <path d="M20.9451 9.04687C19.4634 7.57031 17.4878 6.75 15.4024 6.75C11.0671 6.75 7.55488 10.25 7.55488 14.5703C7.55488 15.9375 7.93902 17.3047 8.59756 18.4531L7.5 22.5L11.6707 21.4062C12.8232 22.0078 14.0854 22.3359 15.4024 22.3359C19.7378 22.3359 23.25 18.8359 23.25 14.5156C23.1951 12.4922 22.4268 10.5234 20.9451 9.04687ZM19.189 17.3594C19.0244 17.7969 18.2561 18.2344 17.872 18.2891C17.5427 18.3437 17.1037 18.3438 16.6646 18.2344C16.3902 18.125 16.0061 18.0156 15.5671 17.7969C13.5915 16.9766 12.3293 15.0078 12.2195 14.8438C12.1098 14.7344 11.3963 13.8047 11.3963 12.8203C11.3963 11.8359 11.8902 11.3984 12.0549 11.1797C12.2195 10.9609 12.439 10.9609 12.6037 10.9609C12.7134 10.9609 12.878 10.9609 12.9878 10.9609C13.0976 10.9609 13.2622 10.9062 13.4268 11.2891C13.5915 11.6719 13.9756 12.6562 14.0305 12.7109C14.0854 12.8203 14.0854 12.9297 14.0305 13.0391C13.9756 13.1484 13.9207 13.2578 13.811 13.3672C13.7012 13.4766 13.5915 13.6406 13.5366 13.6953C13.4268 13.8047 13.3171 13.9141 13.4268 14.0781C13.5366 14.2969 13.9207 14.8984 14.5244 15.4453C15.2927 16.1016 15.8963 16.3203 16.1159 16.4297C16.3354 16.5391 16.4451 16.4844 16.5549 16.375C16.6646 16.2656 17.0488 15.8281 17.1585 15.6094C17.2683 15.3906 17.4329 15.4453 17.5976 15.5C17.7622 15.5547 18.75 16.0469 18.9146 16.1562C19.1341 16.2656 19.2439 16.3203 19.2988 16.375C19.3537 16.5391 19.3537 16.9219 19.189 17.3594Z" fill="white"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "whatsapp-icon"
}
</script>

<style scoped>

</style>