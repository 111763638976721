<template>
    <button
            class="btn-ui"
            :class="{
        'btn-ui_gray': color==='gray',
        'btn-ui_green': color==='green',
        'btn-ui_brandis-blue': color==='brandis-blue',
        'btn-ui_piction-blue': color==='piction-blue',
        'btn-ui_black': color==='black',
        'btn-ui__custom': style
      }"
            :style="style"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "button-ui"
}
</script>
<script setup>
const props = defineProps({
    color: {
        type: String,
    },
    style: {
        type: Object,
    }
})
</script>

<style lang="scss">
.btn-ui {
  width: 100%;
  padding: 24px 36px;
  background: var(--color-red);
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-white);
  text-align: center;
    transition: all ease 0.1s 0s;

  &__custom:hover {
    filter: brightness(1.2);
  }
}

.btn-ui:hover {
  background: var(--color-light-red);
}

.btn-ui_gray {
  line-height: 20px;
  background: var(--color-gray);
}

.btn-ui_gray:hover {
  background: var(--color-medium-gray);
}

.btn-ui_black {
  background: black;
}

.btn-ui_green {
  background: var(--color-green);
}

.btn-ui_green:hover {
  background: var(--color-light-green);
}

.btn-ui_brandis-blue {
  background: var(--color-brandis-blue);
}

.btn-ui_brandis-blue:hover {
  background: var(--color-dodger-blue);
}

.btn-ui_piction-blue {
  background: var(--color-piction-blue);
}

.btn-ui_piction-blue:hover {
  background: var(--color-very-light-azure);
}

</style>
