import {useRoute} from "vue-router";
import {computed} from "vue";

function useCheckRoute(reStr) {
    const PAGE_NAME_RE = RegExp(reStr);
    return computed(() => {
        let result = false;
        const route = useRoute();
        if (route && PAGE_NAME_RE.test(route.path)) {
            result = true;
        }
        return result;
    });
}

export {useCheckRoute};