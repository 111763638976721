<script setup>
import {useRouter} from "vue-router";

const router = useRouter();
const emit = defineEmits(['closeDropdown']);

const goToPage = (page) => {
    emit('closeDropdown');
    router.push(page);
}
</script>

<template>
    <ul class="navbar-dropdown-menu">
        <li @click="goToPage('/owner')" class="navbar-dropdown-menu__link">
            <a href="/owner" @click.prevent>Собственнику</a>
        </li>
        <li @click="goToPage('/renter')" class="navbar-dropdown-menu__link">
            <a href="/renter" @click.prevent>Арендатору</a>
        </li>
        <li @click="goToPage('/investor')" class="navbar-dropdown-menu__link">
            <a href="/investor" @click.prevent>Инвестору</a>
        </li>
    </ul>
</template>

<style lang="scss">
.navbar-dropdown-menu {
  min-width: 220px;
  box-shadow: 0 5px 10px 0 rgba(28, 41, 46, 0.02);

  &__link {
    padding: 12px 20px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-red);
      border-radius: 4px;
      box-shadow: 0 5px 10px 0 rgba(28, 41, 46, 0.02);

      & > a {
        color: var(--color-white);
      }
    }
  }
}
</style>