<template>
  <div class="select-menu">
    <ul class="select-menu__list">
      <menu-item
          v-for="option in options"
          :key="option.value"
          :option-item="option"
          :color-text="colorText"
          :color-option-hover="colorOptionHover"
          :color-option-text-hover="colorOptionTextHover"
          @ch-option="chOption"
      />
    </ul>
  </div>
</template>

<script>
export default {
  name: "select-menu"
}
</script>

<script setup>
import MenuItem from "@/UI/SelectUI/MenuItem";

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  colorBackground: {
    type: String,
    required: true
  },
  colorText: {
    type: String,
    required: true
  },
  colorOptionHover: {
    type: String,
    required: true
  },
  colorOptionTextHover: {
    type: String,
    required: true
  }
})

const emits = defineEmits(['ch-option'])

const chOption = (value) => {
  emits('ch-option', value)
}
</script>

<style lang="scss">
  .select-menu{
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(28, 41, 46, 0.02);
    border-radius: 4px;
    overflow: hidden;
  }
</style>