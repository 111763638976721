<script setup>

</script>

<template>
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2.71289" y="0.193604" width="26.25" height="2.8125" rx="1.40625"
              transform="rotate(45 2.71289 0.193604)" fill="white"/>
        <rect width="26.25" height="2.8125" rx="1.40625"
              transform="matrix(-0.707107 0.707107 0.707107 0.707107 19.2861 0.193604)" fill="white"/>
    </svg>
</template>

<style lang="scss">

</style>