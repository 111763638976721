<template>
  <div class="crumb">
    <span class="crumb__label" @click="router.push(crumb.route)">
      {{crumb['crumbLabel']}}
    </span>
    <BreadcrumbsArrow v-if="!crumb['isLast']"/>
  </div>
</template>

<script setup>
import BreadcrumbsArrow from "@/component/titleheader/UI/Breadcrumbs/BreadcrumbsArrow.vue";
import router from "@/router";

const name = 'ts-crumb';
const props = defineProps(
    {
      crumb: {
        type: Object,
        required: true
      }
    }
)
</script>

<script>
export default {
  name: 'ts-crumb'
}
</script>

<style lang="scss">
.crumb {
  display: flex;
  gap: 12px;
  align-items: center;
  &__label {
    text-wrap: nowrap;
    cursor: pointer;
    line-height: 120%;
    color: var(--color-gray);
  }
}
</style>