<template>
<div class="email_ui">
  <email-svg
      :color-svg="colorEmailSvgCode"
      :svg-height="svgHeight"
      :svg-width="svgWidth"
  />
  <email-address
      :email-address="email"
      :color-address="colorEmailAddressCode"
      :font-size-address="fontSizeAddress"
  />
</div>
</template>

<script>
export default {
  name: "email-ui"
}
</script>
<script setup>
import EmailSvg from "@/UI/EMailUI/EMailSvg.vue";
import EmailAddress from "@/UI/EMailUI/EMailAddress.vue";
import getVarCSS from "@/helpers/getVarCSS";

const props = defineProps({
  email: {
    type: String,
    required: true
  },
  colorEmailSvg: {
    type: String,
    default: '--color-gray'
  },
  colorEmailAddress: {
    type: String,
    default: '--color-white'
  },
  svgWidth: {
    type: [String, Number],
  },
  svgHeight: {
    type: [String, Number],
  },
  fontSizeAddress: {
    type: [String, Number],
  }
})

const colorEmailSvgCode = getVarCSS(props.colorEmailSvg);
const colorEmailAddressCode = getVarCSS(props.colorEmailAddress);
</script>

<style lang="scss">
.email_ui {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 270px;
}
</style>