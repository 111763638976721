<template>
  <div class="email-svg">
  <svg
      id="email-svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g id="email-svg__g"
       stroke="#9399A1"
    >
    <path
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M22 6L12 13L2 6"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    </g>
  </svg>
  </div>
</template>

<script>
export default {
  name: "email-svg"
}
</script>

<script setup>
const props = defineProps({
  colorSvg: {
    type: String,
    default: '#9399A1'
  },
  svgWidth: {
    type: [String, Number],
    default: '24'
  },
  svgHeight: {
    type: [String, Number],
    default: '24'
  }
})

const colorSvgCSS = props.colorSvg;
const svgWidthCSS = props.svgWidth + 'px';
const svgHeightCSS = props.svgHeight + 'px';

</script>

<style lang="scss">
  #email-svg {
    width: 24px;
    height: 24px;
}

  #email-svg__g {
    stroke: v-bind(colorSvgCSS);
    width: v-bind(svgWidthCSS);
    height: v-bind(svgHeightCSS);
  }
</style>