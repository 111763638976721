<script setup>

</script>

<template>
  <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g id="youtube">
      <rect width="30" height="30" rx="15" fill="#F40000"/>
      <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.44687 20.2926C8.15267 20.2056 7.88424 20.0507 7.66422 19.8411C7.4442 19.6316 7.27905 19.3734 7.18271 19.0884C6.63219 17.6155 6.46907 11.4652 7.52933 10.2511C7.88221 9.85609 8.38042 9.61291 8.91583 9.57432C11.7602 9.27576 20.5481 9.31557 21.5574 9.67384C21.8413 9.76374 22.1006 9.91519 22.316 10.1168C22.5314 10.3185 22.6973 10.5651 22.8012 10.8382C23.4027 12.3609 23.423 17.8942 22.7196 19.3571C22.533 19.7379 22.2206 20.0464 21.8327 20.2329C20.7724 20.7504 9.85375 20.7405 8.44687 20.2926ZM12.9632 17.4065L18.0606 14.819L12.9632 12.2116V17.4065Z"
          fill="white"
      />
    </g>
  </svg>
</template>

<style lang="scss">

</style>