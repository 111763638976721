<script setup>

</script>

<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="phone-icon">
        <path
            d="M22.5014 17.42V20.42C22.5025 20.6985 22.4455 20.9742 22.3339 21.2293C22.2223 21.4845 22.0587 21.7136 21.8535 21.9019C21.6483 22.0901 21.406 22.2335 21.1421 22.3227C20.8783 22.4119 20.5988 22.4451 20.3214 22.42C17.2442 22.0856 14.2884 21.0341 11.6914 19.35C9.27523 17.8147 7.22673 15.7662 5.6914 13.35C4.00138 10.7412 2.94964 7.77099 2.6214 4.68C2.59641 4.40347 2.62927 4.12476 2.7179 3.86162C2.80652 3.59849 2.94897 3.35669 3.13616 3.15162C3.32336 2.94655 3.5512 2.78271 3.80519 2.67052C4.05917 2.55833 4.33374 2.50026 4.6114 2.5H7.6114C8.0967 2.49522 8.56719 2.66708 8.93516 2.98353C9.30313 3.29999 9.54348 3.73945 9.6114 4.22C9.73802 5.18007 9.97285 6.12273 10.3114 7.03C10.4459 7.38792 10.4751 7.77691 10.3953 8.15088C10.3155 8.52485 10.1303 8.86811 9.8614 9.14L8.5914 10.41C10.015 12.9135 12.0879 14.9864 14.5914 16.41L15.8614 15.14C16.1333 14.8711 16.4766 14.6858 16.8505 14.6061C17.2245 14.5263 17.6135 14.5555 17.9714 14.69C18.8787 15.0286 19.8213 15.2634 20.7814 15.39C21.2672 15.4585 21.7108 15.7032 22.0279 16.0775C22.3451 16.4518 22.5136 16.9296 22.5014 17.42Z"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
</template>

<style lang="scss">
  #phone-icon {
    stroke: inherit;
  }
</style>