<script setup>

</script>

<template>
  <i class="fa-brands fa-instagram" id="instagram-icon" style="color: #ffffff;"></i>
</template>

<style lang="scss">
#instagram-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(90deg, #ed0016, #bc00ac);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>