<template>
  <div class="pagination-swiper" v-if="swiper && swiper.snapGrid && swiper.snapGrid.length > 1">
    <div
        class="pagination-swiper__btn-prev pagination-swiper__btn"
        @click="slidePrev()"
    >
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="slide-button">
          <circle cx="25" cy="25" r="24" stroke="#DA5E51" stroke-width="2"/>
          <path d="M32 25H18" stroke="#DA5E51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M25 32L18 25L25 18" stroke="#DA5E51" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </g>
      </svg>
    </div>
    <div class="pagination-swiper__navigation navigation">
      <div class="navigation__text" v-if="swiper.snapIndex + 1 < 10">
        <span class="navigation__current">0{{ swiper.snapIndex + 1 }}</span> / {{ swiper.snapGrid.length }}
      </div>
      <div class="navigation__text" v-else>
        <span class="navigation__current">{{ swiper.snapIndex + 1 }}</span> / {{ swiper.snapGrid.length }}
      </div>
    </div>
    <div
        class="pagination-swiper__btn-next pagination-swiper__btn"
        @click="slideNext()"
    >
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="slide-button">
          <circle cx="25" cy="25" r="24" stroke="#DA5E51" stroke-width="2"/>
          <path d="M32 25H18" stroke="#DA5E51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M25 32L18 25L25 18" stroke="#DA5E51" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination-for-swiper',
}
</script>

<script setup>
const props = defineProps({
  startSlide: {
    type: Number,
    default: 1
  },
  swiper: {
    type: Object,
    required: true
  }
})

const slideNext = () => props.swiper.slideNext();
const slidePrev = () => props.swiper.slidePrev();
</script>

<style lang="scss">
.pagination-swiper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__btn {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  &__btn-prev {
  }

  &__navigation {
  }

  &__btn-next {
    transform: rotate(180deg);
  }
}

.slide-button {
}

.navigation {

  &__text {
    font-weight: 500;
    font-size: 18px;
    color: #9399A1;
  }

  &__current {
    font-weight: 700;
    font-size: 32px;
    color: #DA5E51;
  }
}

</style>