<!--
  This Vue.js component represents a custom dropdown UI with a button and a body section.
  It allows toggling the visibility of the dropdown menu on different events, such as mouseenter, mouseleave, and click.
-->

<script setup>
// Importing required components and functions from Vue.js
import SelectArrow from "@/UI/images/SelectArrow.vue";
import {computed, onMounted, ref, watch, defineProps, defineEmits} from "vue";

// Creating reactive variables using ref()
const isVisible = ref(false);
const angleRotate = ref(0);
const dropdownEl = ref({});

// Defining the component's props and default values
const props = defineProps({
  visibleMenu: {
    type: Boolean,
    default: false,
  },
  classButton: {
    type: String,
    default: "",
  },
  classBody: {
    type: String,
    default: "",
  }
});

// Defining the component's emit function to handle custom events
const emit = defineEmits(["update:visibleMenu"]);

// Function to toggle the visibility of the dropdown menu
const toggleVisible = (event) => {
  const handler = {
    click: () => (isVisible.value = !isVisible.value),
  };

  handler[event.type]?.();

  // Emitting custom event to notify parent components about the visibility change
  emit("update:visibleMenu", isVisible.value);
};

// Watching changes to the 'visibleMenu' prop and updating 'isVisible' accordingly
watch(() => props.visibleMenu, (newValue) => {
  isVisible.value = newValue;
});

// Watching changes to 'isVisible' and updating 'angleRotate' accordingly
watch(isVisible, (newValue) => {
  angleRotate.value = newValue ? 180 : 0;
});

// Executed when the component is mounted to set initial visibility based on the prop
onMounted(() => {
  isVisible.value = props.visibleMenu;

  window.addEventListener('click', (event) => {
    if (isVisible.value) {
      if (!dropdownEl.value.contains(event.target)) {
        isVisible.value = false;
      }
    }
  })
});
</script>

<template>
  <div class="dropdown-ui" ref="dropdownEl">
    <!-- The button element to toggle the dropdown menu visibility -->
    <div
        class="dropdown-ui__button button-dropdown-ui"
        @click="toggleVisible"
        :class="{[classButton]: classButton}"
    >
      <button class="button-dropdown-ui__text" type="button" aria-expanded="true">
        <!-- The default slot for the button text content -->
        <slot name="button"></slot>
      </button>
      <div class="button-dropdown-ui__icon">
        <!-- The custom 'SelectArrow' component representing the dropdown arrow -->
        <select-arrow :angle-rotate="angleRotate"/>
      </div>
    </div>
    <!-- The body section of the dropdown that will be shown/hidden based on 'isVisible' -->
    <div
        class="dropdown-ui__body"
        :class="{[classBody]: classBody, 'dropdown-ui__body_active': isVisible}"
    >
      <!-- The default slot for the body content -->
      <slot name="body"></slot>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/helpers.scss';
/* Styling for the custom dropdown UI component */
.dropdown-ui {
  width: 100%;
  position: relative;

  &__button {
    overflow: hidden;
    width: 100%;
    color: var(--color-dropdow-text);
    background-color: var(--color-dropdown-background);
    stroke: var(--color-dropdown-arrow);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    border-radius: 4px;

    & > button {
      color: inherit;
      background-color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  &:hover {
    @media #{$mouse-device} {
      & .dropdown-ui__button {
        color: var(--color-dropdown-hover-text);
      }

    }

  }

  &__body {
    position: absolute;
    left: 0;
    border-radius: 4px;
    z-index: 150;
    opacity: 0;
    display: none;
    animation: 0.3s 1 ease-out fadeOut;
    user-select: none;
    background-color: var(--color-dropdown-background);

    &_active {
      display: block;
      animation: 0.3s 1 ease-out fadeIn;
      opacity: 1;
    }
  }
}
</style>