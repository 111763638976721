<template>
  <div class="images">
    <swiper
        @swiper="setSwiper"
        :loop="true" :speed="1000"
        :slides-per-view="1"
        :autoHeight="false"
        :space-between="70"
        :lazy="true"
    >
      <swiper-slide v-for="url in photos" :key="url">
        <LazyPicture :url="url" :class-name="'images__img'"/>
      </swiper-slide>
    </swiper>
    <div class="images__pagination pagination" v-if="enabled">
      <div class="pagination__item" :class="{ pagination__item_active: activeImgId === index }" :data-id="index"
           v-for="index in photos.length" :key="index" @click="changePhoto"></div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/scss';


export default {
    name: 'images-in-swiper',
    components: {Swiper, SwiperSlide},
    props: {
        photos: {
            type: Array,
            required: true
        },
        id: {
            type: [Number, String],
            required: true,
        },
        activeId: {
            type: [Number, String],
        },
        allActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            swiper: {},
            enabled: true,
            activeImgId: 1,
            publicPath: process.env.BASE_URL,
        }
    },
  methods: {
        changePhoto(e) {
            let idImg = (e.target.dataset.id);
            this.swiper.slideTo(Number(idImg));
        },
        setSwiper(swiper) {
            this.swiper = swiper;
            if (this.allActive) {
                this.swiper.enable();
            } else {
                this.swiper.disable();
            }
        },
    },
    watch: {
        'swiper.realIndex'(newVal) {
            this.activeImgId = newVal + 1;
        },
        activeId(newVal) {
            if (newVal == this.id || this.allActive) {
                this.swiper.enable();
            } else {
                this.swiper.disable();
            }
        },
        'swiper.enabled'(newVal) {
            this.enabled = newVal;
        }
    },
}
</script>
<script setup>
import {cutExtentionInURL} from "@/helpers/cutExtentionInURL";
import LazyPicture from "@/UI/LazyPicture.vue";
</script>

<style lang="scss">
.images {
  position: relative;

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
  }
}

.swiper {
  height: 100%;
}

.swiper-slide {
  overflow: hidden;
}

.pagination {
  position: absolute;
  padding: 0 20px;
  z-index: 2;
  bottom: 12px;
  left: 0;
  height: 6px;
  width: 100%;
  display: flex;
  gap: 6px;

  &__item {
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 3px;
    opacity: 0.4;
    width: 100%;

    &_active {
      opacity: 1;
    }
  }
}
</style>
