<template>
  <div
      class="subtitle"
      :class="{
      'subtitle_white'
      :color==='white'
  }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "subtitle"
}
</script>
<script setup>
const props = defineProps({
  color: {
    type: String,
  }
})
</script>

<style lang="scss">

.subtitle {
  font-weight: 700;
  font-size: 42px;
  color: var(--color-black);
}
.subtitle_white{
  color: var(--color-white);
}
</style>