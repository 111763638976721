// INFO
// *************************************************************
// UI комопонент принимает в props две модели:
// - modelValue;
// - options.
// modelValue выполняет несколько функций, во
// первых это данные, которые отправляет Select
// родителю. А во вторых это модель служит для двустороннего
// связывания. Таким образом можно использовать в родителе как
// v-model, так и v-bind.
// options нужны для передачи компоненту Select списка опций.
// options это массив, состоящий из объектов, которые в свою
// очередь имеют следующие свойства: value - значение опции,
// text - то как будет называться опция
// Пример структуры опций:
// const options = [
//     {value: 213, text: 'Число'},
//     {value: {objectName: 'name'}, text: 'Объект'},
//     {value: 'Я строка', text: 'Строка'}
// ]
// В примере выше я показал, что value может быть абсолютно любым
// Также в props компонент принимает название CSS переменных для
// цвета, вот список:
// - colorSelectArrow, цвет стрелки
// - colorBackground, цвет заднего фона select-a
// - colorText, цвет текста внутри селекта (!!! Не в списке опций)
// - colorBorder, цвет границы селекта (!!! не цвет границы списка опций)
// - colorOptionHover, цвет заднего фона опции, при наведении курсора мыши
// - colorOptionTextHover, цвет текста опции в списке, при наведении курсора мыши
import SelectUI from "@/UI/SelectUI/SelectUI";

export default SelectUI