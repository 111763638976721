<template>
  <div class="mobile-phone-ui">
    <mobile-svg
        :color-svg="colorMobileSvgCode"
        :svg-height="svgHeight"
        :svg-width="svgWidth"
    />
    <mobile-numbers
        :phone="phone"
        :color-phone="colorMobileNumbersCode"
        :font-size-phone="fontSizePhone"
    />
  </div>
</template>

<script>
export default {
  name: "mobile-phone-ui"
}
</script>
<script setup>
import MobileSvg from "@/UI/MobilePhoneUI/MobileSvg.vue";
import MobileNumbers from "@/UI/MobilePhoneUI/MobileNumbers.vue";
import getVarCSS from "@/helpers/getVarCSS";

const props = defineProps({
  colorMobileSvg: {
    type: String,
    default: '--color-gray'
  },
  colorMobileNumbers: {
    type: String,
    default: '--color-black'
  },
  svgWidth: {
    type: [String, Number],
  },
  phone: {
    type: String,
    default: '+7 (4852) 28 29 28'
  },
  svgHeight: {
    type: [String, Number],
  },
  fontSizePhone: {
    type: [String, Number],
  }
})

const colorMobileSvgCode = getVarCSS(props.colorMobileSvg);
const colorMobileNumbersCode = getVarCSS(props.colorMobileNumbers);
</script>

<style lang="scss">
@import 'src/assets/scss/media';

.mobile-phone-ui {
  display: flex;
  align-items: end;
  gap: 12px;
  width: 270px;
}

</style>
