<script setup>
import DropdownUI from "@/UI/DropdownUI/DropdownUI.vue";
import DropdownMenu from "@/modules/NavbarModule/components/DropdownMenu.vue";
import {ref} from "vue";

const visibleDropdown = ref(false);


const hideDropdown = () => {
  visibleDropdown.value = false;
}
</script>

<template>
  <ul class="navbar-menu">
    <li @click="$router.push('/about')">
      <a href="" class="navbar-menu__link" @click.prevent>О нас</a>
    </li>
    <li class="navbar-menu__dropdown">
      <dropdown-u-i v-model:visible-menu="visibleDropdown" :class-body="'navbar-menu__dropdown-body'">
        <template v-slot:button>
          <span class="navbar-menu__dropdown-btn">
            Услуги
          </span>
        </template>
        <template v-slot:body>
          <dropdown-menu @closeDropdown="hideDropdown"/>
        </template>
      </dropdown-u-i>
    </li>
    <li @click="$router.push('/projects')">
      <a href="/projects" class="navbar-menu__link" @click.prevent>Проекты</a>
    </li>
    <li @click="$router.push('/objects')">
      <a href="/objects" class="navbar-menu__link" @click.prevent>Объекты</a>
    </li>
    <!--            <li @click="$router.push('/news')">
                    <a href="/news" class="navbar-menu__link" @click.prevent>Новости</a>
                </li>-->
    <li @click="$router.push('/contacts')">
      <a href="/contacts" class="navbar-menu__link" @click.prevent>Контакты</a>
    </li>
  </ul>
</template>

<style lang="scss">
@import '@/assets/scss/helpers.scss';
@import '@/assets/scss/media.scss';

.navbar-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;

  &__link {
    color: inherit;
    font-size: 16px;
    text-wrap: nowrap;

    &:hover {
      @media #{$mouse-device} {
        color: var(--color-red);
      }
    }
  }

  &__dropdown-btn {
    font-size: 16px;
  }

  &__dropdown-body {
    top: 57px;
    left: -20px;
  }
}

@media screen and (max-width: $media-width-medium) {
  .navbar-menu {
    gap: 20px;

    &__dropdown-body {
      top: 54px;
    }
  }
}

@media screen and (max-width: $media-width-medium-narrow) {
  .navbar-menu {
    &__link {
      font-size: 13px;
      line-height: 1;
    }

    &__dropdown-btn {
      font-size: 13px;
    }

    &__dropdown-body {
      top: 42px;
    }
  }
}
</style>