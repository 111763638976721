<script setup>

</script>

<template>
  <div class="footer-copyright">
    <div class="footer-copyright__line"></div>
    <div class="footer-copyright__content text">
      <div>© «ТС Управление недвижимостью» 2023</div>
      <a @click.prevent @click="$router.push('/contacts')" href="/contacts">
        Политика конфиденциальности</a>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/media.scss";

.footer-copyright {
  &__line {
    width: 100%;
    height: 1px;
    background: var(--color-white);
    opacity: 0.2;
  }

  &__content {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    color: var(--color-gray);
    flex-wrap: wrap;
    gap: 30px;


    & > a {
      color: inherit;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}
</style>