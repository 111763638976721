<script setup>
import CloseIcon from "@/UI/PopupUI/CloseIcon.vue";

const props = defineProps({
  popupController: {
    type: Object,
    default: null,
  }
});
const emit = defineEmits(['closePopup']);

const closePopup = (e) => {
  emit('closePopup');
  if (props.popupController && props.popupController.visibleComponent) {
    props.popupController.toggleVisiblePopup();
  }
}
</script>

<template>
  <div class="popup-ui" @click="closePopup">
    <div class="popup-ui__content" @click.stop>
      <div class="popup-ui__close-btn" @click="closePopup">
        <close-icon/>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.popup-ui {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(9, 22, 48, 0.70) 0%, rgba(9, 22, 48, 0.70) 100%), no-repeat;

  &__content {
    position: relative;
    background-color: var(--color-gray);
    min-width: 60px;
    min-height: 60px;
    max-height: 100%;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__close-btn {
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 20px;
    font-size: 22px;
    opacity: 0.5;
    cursor: pointer;
    user-select: none;
  }

}
</style>