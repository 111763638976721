<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4" id="arrow-breadcrumbs">
      <path d="M4 2L8 6L4 10" stroke="#DBDBDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "breadcrumbs-arrow"
}
</script>

<style lang="scss">
  #arrow-breadcrumbs {

  }
</style>