import { createRouter, createWebHistory } from 'vue-router'

const MainPage = () => import('@/pages/MainPage/MainPage.vue');
const AboutUs = () => import("@/pages/AboutUs/AboutUs.vue");
const Project = () => import("@/pages/Project/Project.vue");
const Object = () => import("@/pages/Object/Object.vue");
const NewsPage = () => import('@/pages/NewsPage/NewsPage.vue');
const NewsItemPage = () => import('@/pages/NewsItemPage/NewsItemPage.vue');
const ProjectsPage = () => import('@/pages/ProjectsPage/ProjectsPage.vue');
const ObjectsPage = () => import("@/pages/ObjectsPage/ObjectsPage.vue");
const ContactsPage = () => import("@/pages/ContactsPage/ContactsPage.vue");
const ServicesPage = () => import('@/pages/ServicesPage/ServicesPage.vue');
const AdminPage = () => import('@/pages/AdminPage/AdminPage.vue');
const AdminDefaultLayout = () => import('@/pages/AdminPage/layouts/AdminDefaultLayout.vue');
const AdminPartnersLayout = () => import('@/pages/AdminPage/layouts/AdminPartnersLayout/AdminPartnersLayout.vue');
const AdminStaffLayout = () => import('@/pages/AdminPage/layouts/AdminStaffLayout/AdminStaffLayout.vue');
const AdminStaffAdd = () => import ('@/pages/AdminPage/layouts/AdminStaffLayout/actions/AdminStaffAdd.vue');
const AdminStaffEdit = () => import('@/pages/AdminPage/layouts/AdminStaffLayout/actions/AdminStaffEdit.vue');
const AdminObjectsLayout = () => import('@/pages/AdminPage/layouts/AdminObjectsLayout/AdminObjectsLayout.vue');
const AdminObjectsAdd = () => import('@/pages/AdminPage/layouts/AdminObjectsLayout/actions/AdminObjectsAdd.vue');
const AdminObjectsEdit = () => import('@/pages/AdminPage/layouts/AdminObjectsLayout/actions/AdminObjectsEdit.vue');

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/news',
    name: 'news',
    component: NewsPage
  },
  {
    path: '/news/:id',
    name: 'news-item',
    component: NewsItemPage
  },
  {
    path: '/projects',
    name: 'projects-page',
    component: ProjectsPage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUs,
  },
  {
    path: '/objects',
    name: 'objects',
    component: ObjectsPage
  },
  {
    path: '/project/:id',
    name: 'project',
    component: Project,
  },
  {
    path: '/object/:id',
    name: 'object',
    component: Object,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsPage,
  },
  {
    path: '/renter',
    name: 'renter',
    component: ServicesPage,
  },
  {
    path: '/owner',
    name: 'owner',
    component: ServicesPage,
  },
  {
    path: '/investor',
    name: 'investor',
    component: ServicesPage,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPage,
    children: [
      {
        path: '',
        name: 'admin-default-page',
        component: AdminDefaultLayout
      },
      {
        path: 'objects',
        name: 'admin-objects',
        component: AdminObjectsLayout
      },
      {
        path: 'partners',
        name: 'admin-partners',
        component: AdminPartnersLayout
      },
      {
        path: 'staff',
        name: 'admin-staff',
        component: AdminStaffLayout
      },
      {
        path: 'staff/add',
        name: 'admin-staff-add',
        component: AdminStaffAdd
      },
      {
        path: 'staff/edit/:id',
        name: 'admin-staff-edit',
        component: AdminStaffEdit
      },
      {
        path: 'objects/add',
        name: 'admin-objects-add',
        component: AdminObjectsAdd
      },
      {
        path: 'objects/edit/:id',
        name: 'admin-objects-edit',
        component: AdminObjectsEdit
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth',
      })
    }
    else {
      return { top: 0 }
    }
  }
})

export default router
