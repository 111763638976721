<template>
    <div class="email-address">
      <a :href="urlEmail">{{emailAddress}}</a>
    </div>
</template>

<script>
export default {
  name: "email-address"
}
</script>

<script setup>
const props = defineProps({
  emailAddress: {
    type: String,
    required: true
  },
  colorAddress: {
    type: String,
    default: '#ffffff'
  },
  fontSizeAddress: {
    type: [String, Number],
    default: '16'
  },
})

const urlEmail = `mailto:${props.emailAddress}`
const colorAddressCSS = props.colorAddress;
const fontSizeAddressCSS = props.fontSizeAddress + 'px';
</script>

<style lang="scss">
.email-address > a {
  color: v-bind(colorAddressCSS);
  font-size: v-bind(fontSizeAddressCSS);
  font-weight: 500;
  text-decoration-line: underline;
}
</style>