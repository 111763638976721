import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import router from './router'
import ui from '@/UI'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import ruRU from "@kangc/v-md-editor/lib/lang/ru-RU";
import Prism from 'prismjs';

const store = createPinia();
const app = createApp(App);

//INIT MD EDITOR
VueMarkdownEditor.use(vuepressTheme, {
	Prism
});
VueMarkdownEditor.lang.use('ru-Ru', ruRU);

ui.forEach( component => {
	app.component(component.name, component)
})

app.use(store).use(router).use(VueMarkdownEditor).mount('#app')
app.config.unwrapInjectedRef = true
