<template>
  <div class="mobile-numbers">
    <a :href="`tel:${phone}`">{{ phone }}</a>
  </div>
</template>

<script>
export default {
  name: "mobile-numbers"
}
</script>

<script setup>
const props = defineProps({
      colorPhone: {
        type: String,
        default: '#242424'
      },
      fontSizePhone: {
        type: [String, Number],
        default: '18'
      },
      phone: {
        type: String,
        default: '+7 (4852) 28 29 28'
      }
    })

const colorPhoneCSS = props.colorPhone;
const fontSizePhoneCSS = props.fontSizePhone + 'px';
</script>

<style lang="scss">
.mobile-numbers > a {
    color: v-bind(colorPhoneCSS);
    font-size: v-bind(fontSizePhoneCSS);
    font-weight: 600;
    line-height: 22px;
}
</style>
