<template>
  <li class="select-menu-item" @click="clickItem()">{{optionItem.text}}</li>
</template>

<script>
export default {
  name: "menu-item"
}
</script>

<script setup>
const props = defineProps({
  optionItem: {
    type: Object,
    required: true
  },
  colorText: {
    type: String,
    required: true
  },
  colorOptionHover: {
    type: String,
    required: true
  },
  colorOptionTextHover: {
    type: String,
    required: true
  }
})

const colorOptionTextHover = props.colorOptionTextHover;
const colorOptionHoverCode = props.colorOptionHover;
const colorTextCode = props.colorText;

const emit = defineEmits(['ch-option'])

const clickItem = () => {
  emit('ch-option', props.optionItem);
}
</script>

<style lang="scss">
  .select-menu-item {
    padding: 12px 20px;
    line-height: 1.5;
    font-size: 16px;
    color: v-bind(colorTextCode);
    cursor: pointer;
    &:hover {
      background: v-bind(colorOptionHoverCode);
      color: v-bind(colorOptionTextHover);
      box-shadow: 0px 5px 10px rgba(28, 41, 46, 0.02);
    }
  }
  @media screen and (max-width: 768px) {
    .select-menu-item{
      font-size: 13px;
      padding: 7px 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    .select-menu-item{
      font-size: 11px;
      padding: 7px 20px;
    }
    .select-menu-item:hover{
      background-color: red;
    }
  }
</style>
