<template>
  <div class="select-ui">
    <div class="select-ui__btn" @click="chVisibleMenu()">
      <span class="select-ui__text">{{ activeOption }}</span>
      <div class="select-ui__arrow">
        <select-arrow
            :angle-rotate="rotateArrow"
            :color-arrow="colorSelectArrowCode"
        />
      </div>
    </div>
    <div class="select-ui__menu" v-if="isVisible">
      <select-menu
          :options="options"
          :color-background="colorBackgroundCode"
          :color-text="colorTextCode"
          :color-option-hover="colorOptionHoverCode"
          :color-option-text-hover="colorOptionTextHoverCode"
          @ch-option="chOption"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "select-ui"
}
</script>

<script setup>
import SelectMenu from "@/UI/SelectUI/SelectMenu";
import SelectArrow from "@/UI/images/SelectArrow.vue";
import getVarCSS from '@/helpers/getVarCSS'
import {computed, onMounted, ref} from "vue";

const props = defineProps({
// Список для выпадающего меню
  options: {
    type: Array,
    required: true
  },
// Первичное значение поля select и обновляемые данные,
// передаваемые в родителя
  modelValue: {
    type: [String, Number],
    default: null
  },
// Цвета
  colorSelectArrow: {
    type: String,
    default: '--color-red'
  },
  colorBackground: {
    type: String,
    default: '--color-white'
  },
  colorText: {
    type: String,
    default: '--color-black'
  },
  colorBorder: {
    type: String,
    default: '--color-light-gray'
  },
  colorOptionHover: {
    type: String,
    default: '--color-red',
  },
  colorOptionTextHover: {
    type: String,
    default: '--color-white',
  }
})

// ГЕНЕРИРУЕМЫЕ СОБЫТИЯ
const emits = defineEmits(['update:modelValue'])

// MOUNTED HOOK
onMounted(() => {
// Если в комопнент не был передан modelValue, то ставим
// первичное значение первой опции, иначе первичное значение
// будет равно modelValue
  if (!props.modelValue) {
    emits('update:modelValue', props.options[0].value)
    activeOption.value = props.options[0].text;
  }
  else {
    activeOption.value = props.modelValue;
  }
});

// ПОЛУЧЕНИЕ КОДА ЦВЕТОВ
const colorSelectArrowCode = getVarCSS(props.colorSelectArrow);
const colorBackgroundCode = getVarCSS(props.colorBackground);
const colorTextCode = getVarCSS(props.colorText);
const colorOptionHoverCode = getVarCSS(props.colorOptionHover);
const colorOptionTextHoverCode = getVarCSS(props.colorOptionTextHover)
const colorBorderCode = getVarCSS(props.colorBorder);

// ПЕРЕМЕННЫЕ SELECT-А
let isVisible = ref(false);
let activeOption = ref('');

// COMPUTED СВОЙСТВА
let rotateArrow = computed(() => {
  return isVisible.value ? 180 : 0
})

// МЕТОДЫ Select-a
// Меняем видимость списка опций
const chVisibleMenu = () => {
  isVisible.value = !isVisible.value;
}
// Меняем активную опцию, обновляем данные и
// закрываем список опций
const chOption = (option) => {
  activeOption.value = option.text;
  emits('update:modelValue', option.value)
  chVisibleMenu();
}
</script>

<style lang="scss">
.select-ui {
  position: relative;
  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 16px 20px;
    border-radius: 6px;
    background: v-bind(colorBackgroundCode);
    border: 1px solid v-bind(colorBorderCode);
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
  &__text {
    color: v-bind(colorTextCode);
    font-size: 16px;
    line-height: 1.5;
  }
  &__menu {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    z-index: 100;
  }
}
@media screen and (max-width: 1024px){

  .select-ui__btn{
    padding: 7px 20px;
  }
  .select-ui__text{
    font-size: 11px;
  }
}
@media screen and (max-width: 768px){

  .select-ui__btn{
    padding: 7px 20px;
  }
  .select-ui__text{
    font-size: 13px;
  }
}

</style>
