import {watch, ref} from "vue";

export default class PopupController {
    #visibleComponent = ref(false);
    get visibleComponent() {
        return this.#visibleComponent;
    }
    set visibleComponent(value) {
        if (typeof value === 'boolean') {
            this.#visibleComponent.value = value;
        }
    }

    constructor(visibleComponent = false) {
        this.#visibleComponent.value = visibleComponent;
        watch(this.#visibleComponent, (newVal) => {
                this.#lockBody();
        })
    }

    toggleVisiblePopup() {
        this.#visibleComponent.value = !this.#visibleComponent.value;
    }
    #lockBody = () => {
        document.body.classList.toggle('_lock');
    }
}
