<!--
Компонент хлебных крошек
Для его работы требуется передать массив оъектов,
которые должны содержать поля crumbLabel - тип String
и route - тип String.

Если хлебных крошек больше чем одна, то
компонент будет отображен на странице.
-->
<template>
  <div class="breadcrumbs" v-if="crumbs.length > 1">
      <crumb
          v-for="crumb in newCrumbsArr"
          :key="crumb.crumbLabel"
          :crumb="crumb"
      />
  </div>
</template>

<script setup>
import Crumb from "@/component/titleheader/UI/Breadcrumbs/Crumb.vue";
import {computed} from "vue";

const props = defineProps(
    {
      crumbs: {
        type: Array,
        required: true,
        crumbLabel: {
          type: String,
          required: true,
        },
        route: {
          type: String,
          required: true
        }
      }
    }
)

let newCrumbsArr = computed(() => {
  return props.crumbs.map((crumb, index) => {
    crumb.isLast = index !== props.crumbs.length - 1 ? false : true;
    return crumb;
  })
});
</script>

<script>
export default {
  name: 'ts-breadcrumbs'
}
</script>

<style lang="scss">
.breadcrumbs {
    display: flex;
    gap: 12px;
}
</style>