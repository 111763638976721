<!--Для подключения UI-элемента в родительком создать функцию data
 и через return вернуть переменную photoUrl с нужным путем до фото-->

<template>
  <picture>
    <source :srcset="`${photoUrl}.webp`" type="image/webp" class="webp-photo">
    <img :src="`${photoUrl}.png`">
  </picture>
</template>

<script>
export default {
  name: "person-photo-ui",
}
</script>
<script setup>

const props = defineProps({
  photoUrl: {
    type: String,
    required: true
  }
})
</script>

<style lang="scss">

</style>
