<template>
  <div class="menu-mobile" ref="menuMobile">
    <NavMenuComponent/>
  </div>
</template>

<script>
import NavMenuComponent from "@/component/NavMenuComponent/NavMenuComponent.vue";

export default {
  name: "TSMobileMenu",
  components: {NavMenuComponent}
}
</script>

<script setup>
import {onMounted, ref} from "vue";

const emit = defineEmits(['closeMenu']);
const menuMobile = ref({});


onMounted(() => {
  const links = document.querySelectorAll('.menu-mobile a');
  links.forEach((el) => {
    el.addEventListener('click', () => {
      emit('closeMenu');
    })
  })
})
</script>

<style lang="scss">
.menu-mobile {
  position: fixed;
  width: 100%;
  height: calc(100dvh - 59px);
  top: 59px;
  background-color: #1A496B;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px 20px;
  animation: 0.5s 0s 1 normal fadeIn;
}
</style>
