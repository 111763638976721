<template>
  <input
      class="input-ui"
      type="text"
      required
      :value="modelValue"
      @input="updateModelValue($event.target.value)"
      :placeholder="placeholder"
  />
</template>

<script>
export default {
  name: "input-ui"
}
</script>
<script setup>

const props = defineProps({
  placeholder: {
    type: String,
  },
  modelValue: {
    type: [String, Number]
  }
})

const emits = defineEmits(['update:modelValue'])

const updateModelValue = (value) => {
  emits('update:modelValue', value);
}

</script>

<style lang="scss">
.input-ui {
  padding: 24px;
  background: #edf1f6;
  border-radius: 4px;
}
</style>