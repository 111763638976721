<template>
  <div class="select-arrow">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="select-arrow">
        <path d="M6 9L12 15L18 9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "select-arrow"
}
</script>

<script setup>
import {computed} from "vue";

const props = defineProps({
  angleRotate: {
    type: Number,
    default: 0
  },
})

const angleRotateCSS = computed( () => {
  return props.angleRotate + 'deg';
});
</script>

<style lang="scss">
.select-arrow {
  transform: rotate(v-bind(angleRotateCSS));
  transition: transform 0.3s ease 0s;
}

#select-arrow {
  stroke: inherit;
}
</style>