import BackgroundLogoArrow from "@/UI/BackgroundLogoArrow.vue";
import {TSBreadcrumbs} from "@/component/titleheader/UI/Breadcrumbs/";
import PaginationForSwiper from "@/UI/PaginationForSwiper.vue";
import ImagesInSwiper from "@/UI/ImagesInSwiper.vue";
import ButtonUI from "@/UI/ButtonUI.vue";
import InputUI from "@/UI/InputUI.vue";
import PersonPhotoUI from "@/UI/PersonPhotoUI.vue";
import SelectUI from "@/UI/SelectUI/";
import MobilePhoneUI from "@/UI/MobilePhoneUI/MobilePhoneUI.vue";
import EMailUI from "@/UI/EMailUI/EMailUI.vue";
import SubtitleUI from "@/UI/SubtitleUI.vue";
import {PopupUI} from "@/UI/PopupUI/";
import DropdownUI from "@/UI/DropdownUI/DropdownUI.vue";

export default [
    BackgroundLogoArrow,
    TSBreadcrumbs,
    PaginationForSwiper,
    ImagesInSwiper,
    ButtonUI,
    InputUI,
    PersonPhotoUI,
    SelectUI,
    MobilePhoneUI,
    EMailUI,
    SubtitleUI,
    PopupUI,
    DropdownUI
]