<template>
  <div class="wrapper">
    <NavbarModule v-if="!isAdmin"/>
    <main class="main">
      <router-view/>
    </main>
    <FooterModule v-if="!isAdmin"/>
  </div>
</template>

<script>
</script>

<script setup>
import FooterModule from '@/modules/FooterModule/FooterModule.vue';
import NavbarModule from "@/modules/NavbarModule/NavbarModule.vue";
import {useCheckRoute} from "@/router/helpers/checkReInRoute";

const isAdmin = useCheckRoute('admin');
</script>

<style lang="scss">
@import "@/assets/scss/nullstyle.scss";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/media.scss";
@import "@/assets/scss/animations.scss";
@import "@/assets/fonts/Golos/golos.scss";
@import "@/assets/fonts/Montserrat/Montserrat.scss";


html {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-black);
}

body._lock {
  overflow: hidden;
}

.main {
  background-color: var(--color-main);
  flex: 100%;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}

/*PC*/
[class*="__container"] {
  max-width: calc(1170px + var(--ct-padding-large) * 2);
  margin: 0 auto;
  padding: 0 var(--ct-padding-large);
}


/*PC*/
@media screen and (max-width: $media-width-large) {
  [class*="__container"] {
    max-width: $media-width-medium;
  }
  html {
    font-size: 30px;
  }
}

@media screen and (max-width: $media-width-medium) {
  [class*="__container"] {
    max-width: $media-width-medium-narrow;
  }
}

@media screen and (max-width: $media-width-medium-narrow) {
  [class*="__container"] {
    max-width: $media-width-small;
  }
}

/*TABLET*/
@media screen and (max-width: $media-width-small) {
  [class*="__container"] {
    max-width: $media-width-micro;
    padding: 0 var(--ct-padding-small);
  }
}

/*MOBILE*/
@media screen and (max-width: $media-width-micro) {
  [class*="__container"] {
    max-width: 100%;
  }
}

</style>
