<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__nav">
        <nav-menu-component/>
      </div>
      <div class="footer__copyright">
        <footer-copyright/>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "ts-footer",
};
</script>

<script setup>
import FooterCopyright from "@/modules/FooterModule/FooterCopyright.vue";
import NavMenuComponent from "@/component/NavMenuComponent/NavMenuComponent.vue";
</script>

<style lang="scss">
@import "@/assets/scss/media.scss";

.footer {
  padding: 40px 0 30px 0;
  background: linear-gradient(180deg, #123b58 0%, #0b2f49 100%);

  &__nav {
    display: flex;
    gap: 60px;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 16px;
  }

  &__copyright {
    width: 100%;
    margin-top: 60px;
  }
}

@media screen and (max-width: $media-width-medium-narrow) {
  .footer__nav {
    font-size: 14px;
  }
}

@media screen and (max-width: $media-width-small) {
  .footer__nav {
    font-size: 16px;
  }
}
</style>
