<!--В родителе импортируются нужные UI элементы icon;
    Создаются две константы const iconWA/iconVib;
    Через v-bind компонент передает дочернему элементу ссылку и
    нужную svg
-->

<template>
<div>
  <a :href="link" target="_blank">
     <component v-if="svgIcon" :is="svgIcon"></component>
    <slot v-else></slot>
  </a>
</div>
</template>

<script>
export default {
  name: "social-network-ui"
}
</script>

<script setup>
const props = defineProps({
  link: {
    type: String,
    required: true
  },
  svgIcon: {
    type: Object
  }
})
</script>

<style lang="scss">

</style>